<template>
    <div class="authwrapper">
        <b-card header="Register" class="lghead">
            <div class="p-3">
                <p>Welcome to MESON</p>
                <!-- email -->
                <b-form-group class="mt-3" label="Email">
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="email" type="email" placeholder="Email" v-bind:[emailState]="emailValidation" />
                    </b-input-group>
                    <b-form-invalid-feedback v-bind:[emailState]="emailValidation"> email format error </b-form-invalid-feedback>
                </b-form-group>
 

                <!-- <b-form-group class="mt-3" label="User type">
                    <b-input-group>
                        <b-form-checkbox v-model="user_type_selected" value="miner" class="mr-4 mt-1">Miner </b-form-checkbox>
                        <b-form-checkbox v-model="user_type_selected" value="cdn_user" class="mr-4 mt-1">CDN user </b-form-checkbox>
                    </b-input-group>
                </b-form-group> -->

                <!-- password -->
                <b-form-group label="Password">
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="password" type="password" v-bind:[passWordState]="passWordValidation" placeholder="Password" />
                    </b-input-group>
                    <b-form-invalid-feedback v-bind:[passWordState]="passWordValidation">
                        must contain number and letter,length 6-20.
                    </b-form-invalid-feedback>
                </b-form-group>

                <!-- confirm password   -->
                <b-form-group label="Confirm Password">
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                            v-model="password_confirm"
                            type="password"
                            v-bind:[passWordConfirmState]="passWordSameValidation"
                            placeholder="Confirm Password"
                        />
                    </b-input-group>
                    <b-form-invalid-feedback v-bind:[passWordConfirmState]="passWordSameValidation">
                        Password must be the same
                    </b-form-invalid-feedback>
                </b-form-group>

                <!-- captcha   -->
                <b-form-group label="Captcha">
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <feather-icon icon="KeyIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="captcha" type="text" placeholder="input your captcha" />
                        <b-input-group-append>
                            <div  class="btn btn-secondary p-0 pt-1" @click="remoteCaptcha">
                                <b-img class="ml-3" v-bind:src="captchaBase64" width="125" height="25" />
                            </div>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <!-- vcode   -->
                <b-form-group label="Email Verification">
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <feather-icon icon="MessageCircleIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="vcode" type="text" placeholder="input your v-code" />
                        <b-input-group-append>
                            <b-overlay style="margin-left: -1px" :show="vsecs <= 0 ? false : true">
                                <b-button variant="secondary" @click="send_vcode"> Send Me V-code </b-button>
                                <template #overlay> <b-spinner small class="mr-3" label="Small Spinner" /> {{ vsecs }}s </template>
                            </b-overlay>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <!-- <b-form-group>
                    <b-form-checkbox id="checkbox-8" class="mt-1" name="checkbox-8" value="Remember_me"> Remember me </b-form-checkbox>
                </b-form-group> -->

                <!-- submit and reset -->
                <b-button type="submit" variant="primary" class="btn-block" @click="register"> Register </b-button>

                <b-card-text class="mt-4 text-center"><img src="/favicon.ico" width="15px" height="15px" style="border-radius:5px;margin-bottom:2px" class="mr-1"/> Already have an account? <a class="goto_login" @click="goto_login">Login</a> </b-card-text>
               
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardText,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BAlert,
    BButton,
    BImg,
    BOverlay,
    BSpinner,
    BFormCheckbox,
} from "bootstrap-vue";

export default {
    components: {
        BCard,
        BCardTitle,
        BCardText,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormInvalidFeedback,
        BAlert,
        BButton,
        BImg,
        BOverlay,
        BSpinner,
        BFormCheckbox,
    },

    data() {
        return {
            password: "",
            password_confirm: "",
            email: "",
            vcode: "",
            captcha: "",
            captchaId: "",
            captchaBase64: "/cap_loading.png",
            vsecs: 0,
            user_type_selected: ["miner", "cdn_user"],
        };
    },
    computed: {
        passWordState() {
            return this.password.length != 0 ? "state" : null;
        },

        passWordConfirmState() {
            return this.password_confirm.length != 0 ? "state" : null;
        },

        passWordValidation() {
            return this.$tools.validator.validatePassword(this.password)
        },
        passWordSameValidation() {
            return this.password == this.password_confirm;
        },

        emailState() {
            return this.email.length != 0 ? "state" : null;
        },
        emailValidation() {
            return this.$tools.validator.validateEmail(this.email)
        },
    },
    methods: {
        goto_login() {
            this.$router.push({
                path: "/login",
            });
        },
        async remoteCaptcha() {
            let resp = await this.$api.captcha.getCaptcha();
            // console.log(resp);
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }
            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.captchaId = resp.result.id;
            this.captchaBase64 = resp.result.content;
        },
        async register() {
            this.show_overlay = true;
            // console.log(this.email, this.password, this.captchaId, this.captcha,this.vcode);
            let resp = await this.$api.user.register(this.email, this.password, this.captchaId, this.captcha,this.vcode);
            this.show_overlay = false;
            if (resp.err != null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                await this.remoteCaptcha();
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                await this.remoteCaptcha();
                return;
            }

            this.$store.commit("auth/UPDATE_MY_LOGIN_ROLES_PERMISSIONS", {
                web_token: resp.result.user.access_token,
                roles: resp.result.user.roles,
                permissions: resp.result.user.permissions,
            });
            this.$router.push({
                path: "/",
            });
        },
        async send_vcode() {
            if (!this.emailValidation) {
                this.$bvToast.toast("email format error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.vsecs = 30;
            var x = setInterval(() => {
                this.vsecs--;
                if (this.vsecs < 0) {
                    clearInterval(x);
                }
            }, 1000);

            let resp = await this.$api.user.getEmailVCode(this.email);
            if (resp.result.meta_status <= 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            } else {
                this.$bvToast.toast("vcode send", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }
        },
    },

    mounted() {
        this.remoteCaptcha();
    },
};
</script>
